function getUrl() {
  const url = new URL(window.location.href)
  const params = url.searchParams
  const token = params.has('token') ? params.get('token') : ''
  const baseUrl = `${process.env.BASE_URL}/web/answer-request`
  return `${baseUrl}?token=${token}`
}

function setFillHeightForOldBrowser() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.onload = () => {
  const element = document.getElementById('wrapper')
  const iframe = document.createElement('iframe')
  iframe.className = 'content'
  iframe.src = getUrl()
  if (element) {
    element.append(iframe)
  }
  window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
    event.preventDefault()
    event.returnValue = ''
  })
  if (!CSS.supports('height', '1dvh')) {
    window.addEventListener('resize', setFillHeightForOldBrowser)
    setFillHeightForOldBrowser()
  }
}

export {}
